.footer {
    width: 100%;
    height: 80px;
    background-color: rgb(19, 19, 19);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: -100px;
  }
  .socialMedia{
    margin-left: 20px;
  }
  .socialMedia svg {
    color: white;
    margin-left: 10px ;
    font-size: 40px;
    cursor: pointer;
  }
  
  .footer p {
    margin-right: 20px;
    color: white;
  }
  @media only screen and (max-width: 800px) {
    .socialMedia svg {
      font-size: 30px;
    }
  }
  